<template>
  <div class="login-page">
    <n-grid
      x-gap="12"
      :cols="3"
    >
      <n-gi />
      <n-gi>
        <n-card
          class="login-card rounded"
          size="large"
          :segmented="{
            content: true,
          }"
        >
          <template #cover>
            <div class="login-card-logo">
              <img
                alt="SkyNexis Logo Full"
                width="292"
                height="47"
                src="@/assets/images/logo-full.svg"
              >
            </div>            
          </template>
          <span style="font-size: 2em">Welcome</span><br>
          <span style="font-size: 1.125em"><b>What would you like to do next?</b></span><br>
          <div class="login-buttons-container">
            <ButtonWithText 
              class="login-button"
              text="Login"
              type="info"
              @click="auth.loginWithRedirect()"
            />
            <ButtonWithText 
              class="signup-button"
              text="Sign Up"
              type="info"
              disabled="true"
            />
          </div>
          <span style="font-size: 0.75em"><b>Terms & Conditions</b></span>
        </n-card>
      </n-gi>
      <n-gi />
    </n-grid>    
  </div>
</template>

<script>
import ButtonWithText from '@/components/shared/ButtonWithText.vue'

export default {
  name: 'Login',
  components: {
    ButtonWithText,
  },
  inject: ['auth'],
  props: {
  },
}
</script>

<style lang="scss">

body {
  background-image: url('~@/assets/images/background-login.svg');
  background-size: 100%;
}

.login-card {
  text-align: center;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  max-width: 620px;

  .login-card-logo {
    img {
        padding: 36px 0px;
    }
  }

  .login-buttons-container {
    padding: 60px 20px 20px 20px;
  }

  .login-button {
    width: 154px;
    padding: 20px;
    margin-right: 10px;
  }

  .signup-button {
    width: 154px;
    padding: 20px;
    margin-left: 10px;
  }
}

</style>
