<template>
  <n-button
    class="rounded"
    :type="type"
    :disabled="disabled"
  >
    {{ text }}
  </n-button>
</template>

<script>
export default {
  name: 'ButtonWithText',
  props: {
    text: {
        type: String,
        default: 'Default',
    },
    type: {
      type: String,
      default: 'Default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>

  .n-card, .n-button {
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
  }

</style>